import React, { useReducer } from "react"

import { MentalHealthReducer } from "./MentalHealthReducer"
import { useAirtableMHFormFields } from "./hooks/useAirtableMHFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const MentalHealthContext = React.createContext()

const MentalHealthProvider = ({ children }) => {
  let formFields = useAirtableMHFormFields()
  formFields = flattenNode(formFields?.allAirtableallMentalHealthFormFields)
  let [mentalHealthState, mentalHealthDispatch] = useReducer(
    MentalHealthReducer,
    {
      ...generateInitialValues({ fields: formFields }),
    }
  )

  return (
    <MentalHealthContext.Provider
      value={{ mentalHealthState, mentalHealthDispatch }}
    >
      {children}
    </MentalHealthContext.Provider>
  )
}

export { MentalHealthContext, MentalHealthProvider }
