import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_MH_FIELDS_QUERY = graphql`
  query AirtableMedEnsureFormFields {
    allAirtableallMentalHealthFormFields {
      nodes {
        data {
          Name
          Type
          Field_Names
        }
      }
    }
  }
`

export const useAirtableMHFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_MH_FIELDS_QUERY)

  return data
}
