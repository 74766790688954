import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"
import { GATSBY_HASURA_GRAPHQL_URL, GATSBY_HASURA_GRAPHQL_ADMIN_SECRET } from "gatsby-env-variables"

export const client = new ApolloClient({
  uri: GATSBY_HASURA_GRAPHQL_URL,
  headers: {
    "x-hasura-admin-secret": GATSBY_HASURA_GRAPHQL_ADMIN_SECRET,
  },
  fetch,
})
